import Meta from 'components/common/Meta';
import Layout from 'components/common/NewLayout';
import Title from 'components/welfare/Title';
import Section1 from 'components/welfare/Section1';
import Section2 from 'components/welfare/Section2';
import Section3 from 'components/welfare/Section3';
import Section4 from 'components/welfare/Section4';
import Section5 from 'components/welfare/Section5';
import React, { useEffect, useState } from 'react';
import Bottom from 'components/welfare/Bottom';
import Navigation from 'components/welfare/Navigation';

const Welfare = () => {
  const [showUI, setShowUI] = useState(false);

  const handleScroll = () => {
    const section = document.getElementById('welfareTitle');
    if (window.scrollY > section.offsetHeight) {
      setShowUI(true);
    } else {
      setShowUI(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Layout theme="white">
      <Title />
      <Section1 />
      <Section2 />
      {/* <Section3 /> */}
      <Section4 />
      <Section5 />
      <Bottom />
      <Navigation show={showUI} />
    </Layout>
  );
};

export default Welfare;
